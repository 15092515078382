import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const RedirectToHomePage: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return null
}
