import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { motion } from 'framer-motion'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import './App.css'
import { HomePage } from './pages/home'
import { ProjectsAndServicesPage } from './pages/projects-and-services'
import { Project01Page } from './pages/projects-and-services/project-01'
import { AboutPage } from './pages/about'
import { NewsPage } from './pages/news'
import { NewsDetailPage } from './pages/news/detail'
import { MarineClinicalTrialServicesPage } from './pages/services/marine-clinical-trial-services'
import { CoralKidsLabPage } from './pages/services/coral-kids-lab'
import { RedirectToHomePage } from './pages/redirect-to-home'
import { store } from './store'
import { setCategories } from './store/blogs'
import { theme } from './theme'
import { useAppDispatch } from './hooks/use-app-dispatch'
import { useMicroCms } from './hooks/use-micro-cms'
import { logger } from './helpers'
import { InnovateAquariumFestivalPage } from './pages/services/innovate-aquarium-festival'
import { initializeAnalytics, trackPageView } from './analytics'

const animationVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
}

const animationTransition = {
  delay: 0.2,
  opacity: {
    duration: 1,
  },
}

const Initialize: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const microCms = useMicroCms()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      const categories = await microCms.getCategories(['NEWS', 'THINK', 'JOIN US'])

      dispatch(setCategories(categories))
    })().catch((error) => logger.error(error))
    initializeAnalytics()
  }, [microCms, dispatch])

  return <>{children}</>
}

const PageRoot: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const scroll = () => {
      const behavior = 'auto'
      const el = document.getElementById(location.hash.slice(1))

      if (el) {
        el.scrollIntoView({
          behavior,
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior,
        })
      }
    }

    setTimeout(scroll, 0)
    trackPageView(location.pathname)
  }, [location])

  return (
    <motion.div
      key={location.pathname}
      initial="hidden"
      animate="enter"
      transition={animationTransition}
      variants={animationVariants}
    >
      {children}
    </motion.div>
  )
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/projects-and-services',
      element: <ProjectsAndServicesPage />,
    },
    {
      path: '/projects-and-services/project-01',
      element: <Project01Page />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/thinking',
      element: <AboutPage />,
    },
    {
      path: '/news',
      element: <NewsPage />,
    },
    {
      path: '/news/:id',
      element: <NewsDetailPage />,
    },
    {
      path: '/articles',
      element: <NewsPage />,
    },
    {
      path: '/articles/:id',
      element: <NewsDetailPage />,
    },
    {
      path: '/services/marine-clinical-trial-services',
      element: <MarineClinicalTrialServicesPage />,
    },
    {
      path: '/services/coral-kids-lab',
      element: <CoralKidsLabPage />,
    },
    {
      path: '/services/innovate-aquarium-festival',
      element: <InnovateAquariumFestivalPage />,
    },
    {
      path: '/recruits',
      element: <RedirectToHomePage />,
    },
    {
      path: '/careers',
      element: <RedirectToHomePage />,
    },
    {
      path: '/company',
      element: <AboutPage />,
      loader: async () => {
        return 'CompanySection'
      },
    },
    {
      path: '/service',
      element: <HomePage />,
      loader: async () => {
        return 'ServicesSection'
      },
    },
    {
      path: '/members',
      element: <AboutPage />,
      loader: async () => {
        return 'MembersSection'
      },
    },
    {
      path: '/company/about',
      element: <AboutPage />,
      loader: async () => {
        return 'CompanySection'
      },
    },
  ]
    .map((config) => ({
      ...config,
      element: <PageRoot>{config.element}</PageRoot>,
    }))
    .flatMap((config) => [
      {
        ...config,
      },
      {
        ...config,
        path: `/:locale${config.path}`,
      },
    ]),
)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Provider store={store}>
          <Initialize>
            <RouterProvider router={router} />
          </Initialize>
        </Provider>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default App
